import {
  Image,
  type StackProps,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { RestrictedFooter } from '@opengovsg/design-system-react'
import { env } from '~/env.mjs'

export const Footer = () => (
  <RestrictedFooter
    appName=""
    appLink="/"
    containerProps={{
      bg: 'transparent',
      w: '100%',
      px: 8,
      pt: 0,
      pb: 8,
    }}
    footerLinks={[
      {
        label: 'FAQ',
        href: `${
          env.NEXT_PUBLIC_DEPLOY_ENV === 'production'
            ? 'https://ask.gov.sg/px'
            : 'https://staging.ask.gov.sg/px'
        }`,
      },
      { label: 'Terms of Use', href: 'https://go.gov.sg/care360terms' },
    ]}
  />
)

export const FooterWithCare360 = () => {
  const align: StackProps['align'] = useBreakpointValue({
    base: 'flex-start',
    md: 'flex-end',
  })
  return (
    <VStack w="full" align={align} spacing={0}>
      <Image alt="care360-logo" src="/assets/care360-logo.svg" px={8} pt={4} />
      <Footer />
    </VStack>
  )
}
